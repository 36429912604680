/* eslint max-len: 'off' */
import React from 'react';

import * as S from './styles';

export default function Svgs() {
  return (
    <S.Svg aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        {/* Adicione seus SVGS aqui separados por tags symbol e seus respectivos viewboxes */}
        {
          <symbol id="icon-facebook" viewBox="0 0 56.693 56.693">
            <title>facebook</title>
            <path d="M40.43,21.739h-7.645v-5.014c0-1.883,1.248-2.322,2.127-2.322c0.877,0,5.395,0,5.395,0V6.125l-7.43-0.029  c-8.248,0-10.125,6.174-10.125,10.125v5.518h-4.77v8.53h4.77c0,10.947,0,24.137,0,24.137h10.033c0,0,0-13.32,0-24.137h6.77  L40.43,21.739z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="icon-twitter" viewBox="0 0 56.693 56.693">
            <title>twitter</title>
            <path d="M52.837,15.065c-1.811,0.805-3.76,1.348-5.805,1.591c2.088-1.25,3.689-3.23,4.444-5.592c-1.953,1.159-4.115,2-6.418,2.454  c-1.843-1.964-4.47-3.192-7.377-3.192c-5.581,0-10.106,4.525-10.106,10.107c0,0.791,0.089,1.562,0.262,2.303  c-8.4-0.422-15.848-4.445-20.833-10.56c-0.87,1.492-1.368,3.228-1.368,5.082c0,3.506,1.784,6.6,4.496,8.412  c-1.656-0.053-3.215-0.508-4.578-1.265c-0.001,0.042-0.001,0.085-0.001,0.128c0,4.896,3.484,8.98,8.108,9.91  c-0.848,0.23-1.741,0.354-2.663,0.354c-0.652,0-1.285-0.063-1.902-0.182c1.287,4.015,5.019,6.938,9.441,7.019  c-3.459,2.711-7.816,4.327-12.552,4.327c-0.815,0-1.62-0.048-2.411-0.142c4.474,2.869,9.786,4.541,15.493,4.541  c18.591,0,28.756-15.4,28.756-28.756c0-0.438-0.009-0.875-0.028-1.309C49.769,18.873,51.483,17.092,52.837,15.065z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="icon-instagram" viewBox="0 0 56.693 56.693">
            <title>instagram</title>
            <path d="M43.414,4.831H13c-5.283,0-9.581,4.297-9.581,9.58v10.12v20.295c0,5.283,4.298,9.58,9.581,9.58h30.415  c5.283,0,9.58-4.297,9.58-9.58V24.53v-10.12C52.994,9.127,48.697,4.831,43.414,4.831z M46.162,10.544l1.096-0.004v1.091v7.312  l-8.375,0.027l-0.029-8.402L46.162,10.544z M21.131,24.53c1.588-2.197,4.164-3.638,7.076-3.638s5.488,1.441,7.074,3.638  c1.033,1.434,1.652,3.188,1.652,5.088c0,4.811-3.918,8.725-8.727,8.725c-4.812,0-8.726-3.914-8.726-8.725  C19.481,27.718,20.098,25.964,21.131,24.53z M48.164,44.825c0,2.621-2.131,4.75-4.75,4.75H13c-2.62,0-4.751-2.129-4.751-4.75V24.53  h7.4c-0.639,1.572-0.998,3.289-0.998,5.088c0,7.473,6.08,13.557,13.556,13.557c7.475,0,13.555-6.084,13.555-13.557  c0-1.799-0.361-3.516-1-5.088h7.402V44.825z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="icon-youtube" viewBox="0 0 24 24">
            <title>youtube</title>
            <path d="M21.80731,7.805a2.79915,2.79915,0,0,0-2.54907-2.53906C17.69061,5.13586,15.36707,5,12.50012,5c-3.45251,0-6.05865.14105-7.74353.27332A2.79932,2.79932,0,0,0,2.1925,7.815C2.08936,8.90021,2,10.33344,2,12.0423c0,1.66632.08862,3.07422.19128,4.14594a2.79852,2.79852,0,0,0,2.56384,2.53833C6.43994,18.85883,9.04657,19,12.50012,19c2.86694,0,5.19049-.13586,6.75812-.266a2.799,2.799,0,0,0,2.54907-2.539C21.91052,15.11548,22,13.69189,22,12S21.91052,8.88452,21.80731,7.805ZM9.77594,14.93878V9.06122L15.574,12Z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="icon-whatsapp" viewBox="0 0 56.693 56.693">
            <title>whatsapp</title>
            <g>
              <path d="M46.3802,10.7138c-4.6512-4.6565-10.8365-7.222-17.4266-7.2247c-13.5785,0-24.63,11.0506-24.6353,24.6333   c-0.0019,4.342,1.1325,8.58,3.2884,12.3159l-3.495,12.7657l13.0595-3.4257c3.5982,1.9626,7.6495,2.9971,11.7726,2.9985h0.01   c0.0008,0-0.0006,0,0.0002,0c13.5771,0,24.6293-11.0517,24.635-24.6347C53.5914,21.5595,51.0313,15.3701,46.3802,10.7138z    M28.9537,48.6163h-0.0083c-3.674-0.0014-7.2777-0.9886-10.4215-2.8541l-0.7476-0.4437l-7.7497,2.0328l2.0686-7.5558   l-0.4869-0.7748c-2.0496-3.26-3.1321-7.028-3.1305-10.8969c0.0044-11.2894,9.19-20.474,20.4842-20.474   c5.469,0.0017,10.6101,2.1344,14.476,6.0047c3.8658,3.8703,5.9936,9.0148,5.9914,14.4859   C49.4248,39.4307,40.2395,48.6163,28.9537,48.6163z" />
              <path d="M40.1851,33.281c-0.6155-0.3081-3.6419-1.797-4.2061-2.0026c-0.5642-0.2054-0.9746-0.3081-1.3849,0.3081   c-0.4103,0.6161-1.59,2.0027-1.9491,2.4136c-0.359,0.4106-0.7182,0.4623-1.3336,0.1539c-0.6155-0.3081-2.5989-0.958-4.95-3.0551   c-1.83-1.6323-3.0653-3.6479-3.4245-4.2643c-0.359-0.6161-0.0382-0.9492,0.27-1.2562c0.2769-0.2759,0.6156-0.7189,0.9234-1.0784   c0.3077-0.3593,0.4103-0.6163,0.6155-1.0268c0.2052-0.4109,0.1027-0.7704-0.0513-1.0784   c-0.1539-0.3081-1.3849-3.3379-1.8978-4.5706c-0.4998-1.2001-1.0072-1.0375-1.3851-1.0566   c-0.3585-0.0179-0.7694-0.0216-1.1797-0.0216s-1.0773,0.1541-1.6414,0.7702c-0.5642,0.6163-2.1545,2.1056-2.1545,5.1351   c0,3.0299,2.2057,5.9569,2.5135,6.3676c0.3077,0.411,4.3405,6.6282,10.5153,9.2945c1.4686,0.6343,2.6152,1.013,3.5091,1.2966   c1.4746,0.4686,2.8165,0.4024,3.8771,0.2439c1.1827-0.1767,3.6419-1.489,4.1548-2.9267c0.513-1.438,0.513-2.6706,0.359-2.9272   C41.211,33.7433,40.8006,33.5892,40.1851,33.281z" />
            </g>
          </symbol>
        }
        ,{' '}
        {
          <symbol id="icon-search" viewBox="0 0 1792 1792">
            <title>search</title>
            <path d="M1216 832q0-185-131.5-316.5t-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5 316.5-131.5 131.5-316.5zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225-55.5-273.5 55.5-273.5 150-225 225-150 273.5-55.5 273.5 55.5 225 150 150 225 55.5 273.5q0 220-124 399l343 343q37 37 37 90z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="arrow-banner" viewBox="0 0 48 48">
            <title>arrow</title>
            <path d="M0 0h48v48h-48z" fill="none" />
            <path d="M40 22h-24.34l11.17-11.17-2.83-2.83-16 16 16 16 2.83-2.83-11.17-11.17h24.34v-4z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="icon-tag" viewBox="0 0 32 32">
            <title>tag</title>
            <g id="tag">
              <path
                clipRule="evenodd"
                d="M31.391,13.883l-5-8c-0.73-1.169-2.012-1.88-3.391-1.88H4   c-2.209,0-4,1.791-4,4v16c0,2.209,1.791,4,4,4h19c1.379,0,2.66-0.711,3.391-1.881l5-8C32.203,16.827,32.203,15.18,31.391,13.883z    M29.695,17.062l-5,8.002c-0.367,0.588-1.002,0.939-1.695,0.939H4c-1.103,0-2-0.898-2-2v-16c0-1.103,0.897-2,2-2h19   c0.693,0,1.328,0.352,1.695,0.939l5,8C30.098,15.587,30.098,16.419,29.695,17.062z"
                fill="#333333"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M23,13.003c-1.658,0-3,1.343-3,3c0,1.657,1.342,3,3,3   c1.656,0,3-1.344,3-3C26,14.346,24.656,13.003,23,13.003z M23,18.004c-1.105,0-2-0.896-2-2c0-1.104,0.895-2,2-2   c1.104,0,2,0.896,2,2C25,17.107,24.104,18.004,23,18.004z"
                fill="#333333"
                fillRule="evenodd"
              />
            </g>
          </symbol>
        }
        ,{' '}
        {
          <symbol id="icon-play" viewBox="0 0 512 512">
            <title>play</title>
            <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="arrow" viewBox="0 0 48 48">
            <title>arrow</title>
            <path d="M0 0h48v48h-48z" fill="none" />
            <path d="M40 22h-24.34l11.17-11.17-2.83-2.83-16 16 16 16 2.83-2.83-11.17-11.17h24.34v-4z" />
          </symbol>
        }
        ,{' '}
        {
          <symbol id="arrow-single" viewBox="0 0 792 792">
            <path
              d="M622.955,342.127L268.424,20.521c-27.36-27.36-71.677-27.36-99.037,0c-27.36,27.36-27.36,71.676,0,99.037
            l304.749,276.468L169.387,672.492c-27.36,27.359-27.36,71.676,0,99.036s71.677,27.36,99.037,0l354.531-321.606
            c14.783-14.783,21.302-34.538,20.084-53.897C644.225,376.665,637.738,356.911,622.955,342.127z"
            />
          </symbol>
        }
      </defs>
    </S.Svg>
  );
}
