import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import { Fonts } from '../styles/fonts';
import { GlobalStyle } from '../styles/global';
import { theme } from '../styles/theme';
import { Svgs } from '../components';

export default function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <ThemeProvider theme={theme}>
          <>
            <Fonts />
            <GlobalStyle />
            <Svgs />
            <main>{children}</main>
          </>
        </ThemeProvider>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
