import styled from 'styled-components';

export const BlogPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 60px;
  max-width: ${(props) => props.theme.grid.container};
  position: relative;
  width: 100%;
`;
