import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Icon from '../icon';

import * as S from './styles';

export default function Footer() {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo-primeiro-contato-sci-fi-rodape.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 150, maxWidth: 362, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <S.Footer>
      <S.Content>
        <S.Logo cover direction="right" bg="#ffffff" duration={0.6} to="/">
          <Img
            alt="Logo Primeiro Contato Sci Fi"
            style={{ height: '100%' }}
            fluid={logo.childImageSharp.fluid}
            title="Logo Primeiro Contato Sci Fi"
          />
        </S.Logo>

        <S.SocialMedias>
          <S.Item className="-youtube" href="https://www.youtube.com/channel/UC4BeJvOp0inJ4ZCvEXGF_uA" target="_blank">
            <Icon name="icon-youtube" />
          </S.Item>
          <S.Item href="https://www.instagram.com/primeirocontatoscifi/" target="_blank">
            <Icon name="icon-instagram" />
          </S.Item>
          <S.Item href="https://twitter.com/pcscifi" target="_blank">
            <Icon name="icon-twitter" />
          </S.Item>
          <S.Item href="https://www.facebook.com/primeirocontato/" target="_blank">
            <Icon name="icon-facebook" />
          </S.Item>
        </S.SocialMedias>

        <S.Copyright>
          Desenvolvido por{' '}
          <S.LinkCopy href="https://marcoaring.com.br" target="_blank">
            Marco Aring
          </S.LinkCopy>
          .
        </S.Copyright>
      </S.Content>
    </S.Footer>
  );
}
