import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';

import * as S from './styles';

export default function PreviousNext({ next, previous }) {
  return (
    <S.WrapPreviousNext className={(!next && '-singlePrevious') || (!previous && '-singleNext')}>
      {next && (
        <S.LinksNavigation className="-previous" cover direction="left" bg="#ffffff" duration={0.6} to={`/${next.slug}`}>
          <Icon name="arrow-single" />
          <S.LinkInfos>
            <S.LinksLabel>Próximo post:</S.LinksLabel>
            <S.LinksTitle>{next.title}</S.LinksTitle>
          </S.LinkInfos>
        </S.LinksNavigation>
      )}
      {previous && (
        <S.LinksNavigation className="-next" cover direction="right" bg="#ffffff" duration={0.6} to={`/${previous.slug}`}>
          <S.LinkInfos>
            <S.LinksLabel>Post anterior:</S.LinksLabel>
            <S.LinksTitle>{previous.title}</S.LinksTitle>
          </S.LinkInfos>
          <Icon name="arrow-single" />
        </S.LinksNavigation>
      )}
    </S.WrapPreviousNext>
  );
}

PreviousNext.propTypes = {
  next: PropTypes.object,
  previous: PropTypes.object,
};
