import React from 'react';
import PropTypes from 'prop-types';
import { Disqus } from 'gatsby-plugin-disqus';

import * as S from './styles';

export default function Comments({ id, slug, title }) {
  const fullUrl = `https://primeirocontatoscifi.com.br${slug}`;
  const configDisqus = {
    identifier: id,
    title: title,
    url: fullUrl,
  };

  return (
    <S.Wrapper>
      <S.TitleComments>Comentários</S.TitleComments>
      <Disqus config={configDisqus} />
    </S.Wrapper>
  );
}

Comments.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
