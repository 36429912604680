import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import * as S from './styles';

export default function Menu({ postCategories }) {
  const url = typeof window !== 'undefined' ? window.location : '';
  const { allWpCategory, allWpPage } = useStaticQuery(graphql`
    query {
      allWpCategory {
        edges {
          node {
            count
            databaseId
            name
            slug
          }
        }
      }
      allWpPage {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  return (
    <S.Menu>
      <S.List>
        {allWpCategory.edges.map((category, index) => {
          if (category.node.databaseId !== 1 && category.node.count > 0) {
            const active = postCategories
              ? postCategories.nodes.find((postCategory) => postCategory.databaseId === category.node.databaseId)
              : null;
            return (
              <S.Item className={`item-${category.node.slug} ${active ? '-active' : ''}`} key={category.node.databaseId}>
                <S.Link cover direction="left" bg="#ffffff" duration={0.6} to={`/categorias/${category.node.slug}`}>
                  {category.node.name}
                </S.Link>
              </S.Item>
            );
          }
        })}
        {allWpPage.edges.map((page, index) => {
          const active = url ? url.pathname.replace('/', '') === page.node.slug : null;
          return (
            <S.Item className={`item-${page.node.slug} ${active ? '-active' : ''}`} key={index}>
              <S.Link cover direction="left" bg="#ffffff" duration={0.6} to={`/${page.node.slug}`}>
                {page.node.title}
              </S.Link>
            </S.Item>
          );
        })}
      </S.List>
    </S.Menu>
  );
}

Menu.propTypes = {
  postCategories: PropTypes.object,
};
