import React, { useRef } from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import Icon from '../icon';

import 'slick-carousel/slick/slick.css';
import * as S from './styles';

export default function Banner() {
  const slideRef = useRef();
  const banners = useStaticQuery(graphql`
    query BannerQuery {
      allWpPost(filter: { acf: { featuredPost: { eq: "Sim" } } }) {
        edges {
          node {
            author {
              node {
                name
                avatar {
                  url
                }
              }
            }
            categories {
              nodes {
                name
                slug
                databaseId
              }
            }
            date(formatString: "D [de] MMMM [de] YYYY", locale: "pt-br")
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 400, maxWidth: 1160) {
                      ...GatsbyImageSharpFluid_withWebp
                      src
                    }
                  }
                }
                title
              }
            }
            slug
            title
          }
        }
      }
    }
  `);

  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 200,
  };

  return (
    <S.Banner>
      <Slider ref={slideRef} {...settings}>
        {banners.allWpPost.edges.map((banner, index) => {
          return (
            <S.SliderItem key={index}>
              <S.ContentBackground>
                <Img
                  alt={banner.node.featuredImage.node.altText}
                  fluid={banner.node.featuredImage.node.localFile.childImageSharp.fluid}
                  style={{ height: '100%' }}
                  title={banner.node.featuredImage.node.title}
                />
              </S.ContentBackground>

              <S.InfosPost>
                <S.Categories>
                  <Icon name="icon-tag" />

                  {banner.node.categories.nodes.map((category, countCategory) => {
                    return (
                      <React.Fragment key={category.databaseId}>
                        {countCategory ? ', ' : ''}
                        <S.Category cover direction="left" bg="#ffffff" duration={0.6} to={`/categorias/${category.slug}`}>
                          {category.name}
                        </S.Category>
                      </React.Fragment>
                    );
                  })}
                </S.Categories>

                <S.Title cover direction="left" bg="#ffffff" duration={0.6} to={`/${banner.node.slug}`}>
                  {banner.node.title}
                </S.Title>

                <S.Footer>
                  <S.Author>
                    <S.Avatar src={banner.node.author.node.avatar.url} alt={banner.node.author.node.name} />
                    <S.Name>{banner.node.author.node.name}</S.Name>
                  </S.Author>
                  <S.Date>{banner.node.date}</S.Date>
                </S.Footer>
              </S.InfosPost>
            </S.SliderItem>
          );
        })}
      </Slider>

      <S.Arrows>
        <S.Prev onClick={() => slideRef.current.slickPrev()}>
          <Icon name="arrow-banner" />
        </S.Prev>
        <S.Next onClick={() => slideRef.current.slickNext()}>
          <Icon name="arrow-banner" />
        </S.Next>
      </S.Arrows>
    </S.Banner>
  );
}
