import styled from 'styled-components';
import { media } from '../../styles/media-queries';

export const Content = styled.main`
  margin: 0 auto;
  max-width: ${(props) => props.theme.grid.container};
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 2em;
  margin: 30px 0;

  ${media.mobile`
    padding: 0 15px;
  `}
`;

export const Description = styled.p`
  margin-bottom: 30px;

  ${media.mobile`
    padding: 0 15px;
  `}
`;

export const Input = styled.input`
  border: none;
  border-bottom: 3px solid #aaaaaa;
  display: block;
  font-size: 1.5em;
  height: 50px;
  margin-bottom: 15px;
  outline: none;
  width: 100%;

  ${media.mobile`
    margin: 0 auto 15px;
    width: calc(100% - 30px);
  `}
`;

export const Strong = styled.strong`
  font-weight: 700;
`;

export const CountSearch = styled.p`
  text-align: right;
  margin-bottom: 30px;

  ${media.mobile`
    padding: 0 15px;
  `}
`;
