import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import BlogPosts from '../blog-posts';
import * as S from './styles';

export default function Search({ posts }) {
  const [search, changeSearch] = useState('');
  const [searchResult, changeSearchResults] = useState([]);

  useEffect(() => {
    if (search.length > 1) {
      changeSearchResults(posts.filter((post) => post.node.title.toLowerCase().indexOf(search.toLowerCase()) !== -1));
    }
  }, [search]);

  return (
    <S.Content>
      <S.Title>Busca</S.Title>
      <S.Description>Digite o(s) termo(s) que você deseja e aguarde os resultados aparecerem</S.Description>

      <S.Input
        autoComplete="off"
        type="text"
        name="search"
        placeholder="O que você deseja procurar?"
        onChange={(evt) => {
          changeSearch(evt.target.value);
        }}
      />

      {search && (
        <S.CountSearch>
          {searchResult.length} resultados encontrados para a busca <S.Strong>{search}</S.Strong>.
        </S.CountSearch>
      )}

      {searchResult.length > 0 && <BlogPosts posts={searchResult} />}
    </S.Content>
  );
}

Search.propTypes = {
  posts: PropTypes.array.isRequired,
};
