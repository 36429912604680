import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../../styles/media-queries';

export const Footer = styled.footer`
  background-color: ${(props) => props.theme.palette.black};
  margin: 0 auto;
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.grid.container};
  padding: 30px;
  width: 100%;
`;

export const Logo = styled(AniLink)`
  display: block;
  margin-bottom: 15px;

  ${media.mobile`
    width: 220px;
  `}

  ${media.tablet`
    width: 220px;
  `}

  ${media.desktop`
    width: 362px;
  `}
`;

export const SocialMedias = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 30px;
`;

export const Item = styled.a`
  border: 2px solid ${(props) => props.theme.palette.white};
  display: block;
  height: 35px;
  margin-right: 10px;
  transition: all 0.2s linear;
  width: 35px;

  > svg {
    display: block;
    fill: ${(props) => props.theme.palette.white};
    height: 30px;
    margin: 0 auto;
    transition: fill 0.2s linear;
    width: 20px;
  }

  &.-youtube > svg {
    height: 30px;
    width: 25px;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.white};

    > svg {
      fill: ${(props) => props.theme.palette.black};
    }
  }
`;

export const Copyright = styled.p`
  color: ${(props) => props.theme.palette.white};
  font-size: 11px;
`;

export const LinkCopy = styled.a`
  color: ${(props) => props.theme.palette.white};
  font-weight: bold;
  text-decoration: none;
`;
