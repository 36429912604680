import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export const Pagination = styled.aside`
  margin-bottom: 60px;
`;

export const PaginationWrap = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ActionLinks = styled(AniLink)`
  align-items: center;
  background-color: ${(props) => props.theme.palette.black};
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.palette.black};
  display: flex;
  height: 34px;
  justify-content: center;
  transition: all 0.3s linear;
  width: 34px;

  > svg {
    fill: ${(props) => props.theme.palette.white};
    height: 80%;
    transition: all 0.3s linear;
    width: 80%;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.white};
    border-color: ${(props) => props.theme.palette.black};

    > svg {
      fill: ${(props) => props.theme.palette.black};
    }
  }

  &.next {
    > svg {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    background-color: #cccccc;
    border-color: ${(props) => props.theme.palette.white};

    &:hover {
      cursor: default;

      > svg {
        fill: ${(props) => props.theme.palette.white};
      }
    }
  }
`;

export const TextPages = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0 30px;
`;
