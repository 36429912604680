/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';

export const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Stellar';
    src: url('/fonts/Stellar-Regular.eot');
    src: url('/fonts/Stellar-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/Stellar-Regular.woff2') format('woff2'), url('/fonts/Stellar-Regular.woff') format('woff'), url('/fonts/Stellar-Regular.ttf') format('truetype'), url('/fonts/Stellar-Regular.svg#Stellar-Regular') format('svg');
    font-display: fallback;
    font-style: normal;
    font-weight: normal;
  }
`;
