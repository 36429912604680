import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../../styles/media-queries';

export const Banner = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: ${(props) => props.theme.grid.container};
  position: relative;
  width: 100%;

  > .slick-slider {
    height: 100%;
    width: 100%;

    > .slick-dots {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%;

      > li {
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        position: relative;

        > button {
          background: #eeeeee;
          border-radius: 10px;
          border: none;
          cursor: pointer;
          display: block;
          font-size: 0;
          font-weight: bold;
          height: 8px;
          line-height: 20px;
          outline: none;
          text-align: center;
          width: 8px;
          transition: all 0.3s linear;
        }

        &.slick-active button {
          background: ${(props) => props.theme.palette.black};
          opacity: 1;
          width: 15px;
        }
      }

      ${media.desktop`
        display: none !important;
      `}
    }
  }

  ${media.mobile`
    height: 100%;
    margin: 0 auto 30px;
  `}

  ${media.tablet`
    height: 100%;
    margin: 15px auto 60px;
  `}

  ${media.desktop`
    height: 480px;
    margin: 30px auto 60px;
  `}
`;

export const SliderItem = styled.div`
  position: relative;

  ${media.mobile`
    height: 100%;
  `}

  ${media.tablet`
    height: 100%;
  `}

  ${media.desktop`
    height: 500px;
  `}
`;

export const ContentBackground = styled.div`
  height: 400px;
  overflow: hidden;

  ${media.mobile`
    height: 200px;
  `}
`;

export const InfosPost = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.black};
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;

  ${media.mobile`
    padding: 10px;
    width: 100%;
  `}

  ${media.tablet`
    padding: 10px;
    width: 100%;
  `}

  ${media.desktop`
    bottom: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    left: 50%;
    padding: 15px 30px;
    position: absolute;
    transform: translate(-50%, 0);
    width: 749px;
  `}
`;

export const Categories = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    display: block;
    height: 15px;
    margin-right: 10px;
    transform: rotate(-45deg);
    width: 15px;
  }

  ${media.mobile`
    margin-bottom: 10px;
  `}

  ${media.tablet`
    margin-bottom: 10px;
  `}

  ${media.desktop`
    margin-bottom: 15px;
  `}
`;

export const Category = styled(AniLink)`
  color: ${(props) => props.theme.palette.black};
  display: block;
  line-height: 10px;
  position: relative;
  text-decoration: none;

  &:after {
    background-color: ${(props) => props.theme.palette.black};
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    transition: all 0.3s linear;
    width: 0;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

export const Title = styled(AniLink)`
  color: ${(props) => props.theme.palette.black};
  text-decoration: none;

  ${media.mobile`
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 15px;
  `}

  ${media.tablet`
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 15px;
  `}

  ${media.desktop`
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 30px;
  `}
`;

export const Footer = styled.div`
  align-items: center;
  justify-content: center;

  ${media.mobile`
    display: none;
  `}

  ${media.tablet`
    display: flex;
  `}

  ${media.desktop`
    display: flex;
  `}
`;

export const Author = styled.div`
  align-items: center;
  display: flex;

  &:after {
    background-color: ${(props) => props.theme.palette.black};
    content: '';
    height: 3px;
    margin: 0 10px;
    width: 3px;
  }
`;

export const Avatar = styled.img`
  border-radius: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  height: 40px;
  margin-right: 15px;
  width: 40px;
`;

export const Name = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin-top: -5px;
`;

export const Date = styled.div`
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin-top: -5px;
`;

export const Arrows = styled.div`
  justify-content: space-between;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 105%;

  > button {
    background-color: ${(props) => props.theme.palette.white};
    border-radius: 100%;
    border: none;
    height: 51px;
    outline: none;
    transition: all 0.4s linear;
    width: 51px;

    > svg {
      display: block;
      fill: ${(props) => props.theme.palette.black};
      height: 60%;
      margin: 0 auto;
      transition: fill 0.2s linear;
      width: 60%;
    }

    &:hover {
      background-color: ${(props) => props.theme.palette.black};
      cursor: pointer;

      > svg {
        fill: ${(props) => props.theme.palette.white};
      }
    }
  }

  ${media.mobile`
    display: none;
  `}

  ${media.tablet`
    display: none;
  `}

  ${media.desktop`
    display: flex;
  `}
`;

export const Prev = styled.button``;

export const Next = styled.button`
  transform: rotate(180deg);
`;
