import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import * as S from './styles';

export default function Post({ post }) {
  return (
    <S.Post>
      <S.Header>
        <S.FeaturedImage>
          <Img
            alt={post.featuredImage.node.altText}
            fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
            style={{ height: '100%' }}
            title={post.featuredImage.node.title}
          />
        </S.FeaturedImage>
        <S.Title>
          <S.LinkTitle cover direction="left" bg="#ffffff" duration={0.6} to={`/${post.slug}`}>
            {post.title}
          </S.LinkTitle>
        </S.Title>
        <S.ContinueRead cover direction="left" bg="#ffffff" duration={0.6} to={`/${post.slug}`}>
          Continue Lendo
        </S.ContinueRead>
      </S.Header>

      <S.Infos>
        <S.Author>
          <S.Avatar src={post.author.node.avatar.url} alt={post.author.node.name} />
          <S.Name>{post.author.node.name}</S.Name>
        </S.Author>
        <S.Date>{post.date}</S.Date>
      </S.Infos>

      <S.Excerpt dangerouslySetInnerHTML={{ __html: post.excerpt }} />
    </S.Post>
  );
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
};
