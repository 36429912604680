import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../../styles/media-queries';

export const Post = styled.article`
  ${media.mobile`
    margin-bottom: 30px;
    padding: 0 15px;
    width: 100%;
  `}

  ${media.tablet`
    margin-bottom: 4%;
    padding: 0 15px;
    width: 42%;
  `}

  ${media.desktop`
    margin-bottom: 4%;
    margin-right: 4%;
    width: 38%;
  `}

  &:nth-child(2n+0) {
    ${media.tablet`
      margin-right: 0;
    `}

    ${media.desktop`
      margin-right: 0;
    `}
  }

  &:nth-child(4n),
  &:nth-child(4n + 1) {
    ${media.tablet`
      width: 58%;
    `}

    ${media.desktop`
      width: 58%;
    `}
  }
`;

export const Header = styled.header`
  position: relative;
  z-index: 1;

  &:hover {
    cursor: pointer;

    > h1 {
      left: -100px;
      opacity: 0;
    }

    > a {
      opacity: 1;
      right: 15px;
    }
  }
`;

export const FeaturedImage = styled.div`
  height: 310px;
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;

  &:after {
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }

  ${media.mobile`
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  `}
`;

export const Title = styled.h1`
  margin: 0;
  transition: all 0.5s ease;
  width: 100%;

  ${media.mobile`
    font-size: 22px;
    line-height: 26px;
    padding: 0 10px 20px;
    text-align: center;
  `}

  ${media.tablet`
    bottom: 20px;
    left: 0;
    padding: 15px 30px;
    position: absolute;
    text-align: left;
    z-index: 3;
  `}

  ${media.desktop`
    bottom: 20px;
    left: 0;
    padding: 15px 30px;
    position: absolute;
    text-align: left;
    z-index: 3;
  `}
`;

export const LinkTitle = styled(AniLink)`
  background: ${(props) => props.theme.palette.white};
  border: 8px solid ${(props) => props.theme.palette.white};
  box-decoration-break: clone;
  color: ${(props) => props.theme.palette.black};
  display: inline;
  font-size: 25px;
  line-height: 39px;
  text-decoration: none;
`;

export const ContinueRead = styled(AniLink)`
  background: ${(props) => props.theme.palette.white};
  bottom: 34px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.45);
  color: ${(props) => props.theme.palette.black};
  margin: 0;
  opacity: 0;
  padding: 10px 15px 13px 15px;
  position: absolute;
  right: -50px;
  text-decoration: none;
  transition: all 0.5s ease;
  z-index: 3;

  &:hover {
    background: ${(props) => props.theme.palette.black};
    color: ${(props) => props.theme.palette.white};
  }

  ${media.mobile`
    display: none;
  `}

  ${media.tablet`
    display: none;
  `}
`;

export const Infos = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

export const Author = styled.div`
  align-items: center;

  &:after {
    background-color: ${(props) => props.theme.palette.black};
    content: '';
    height: 3px;
    margin: 0 10px;
    width: 3px;
  }

  ${media.mobile`
    display: none;
  `}

  ${media.tablet`
    display: none;
  `}

  ${media.desktop`
    display: flex;
  `}
`;

export const Avatar = styled.img`
  border-radius: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  height: 40px;
  margin-right: 15px;
  width: 40px;
`;

export const Name = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin-top: -5px;
`;

export const Date = styled.div`
  font-size: 17px;
  font-weight: bold;
  line-height: 16px;
  margin-top: -5px;

  ${media.mobile`
    color: #848484;
  `}
`;

export const Excerpt = styled.div`
  font-size: 16px;
  line-height: 25px;
  min-height: 50px;
  text-align: center;
`;
