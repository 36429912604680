import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../../styles/media-queries';

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${(props) => props.theme.grid.container};
  width: 100%;

  ${media.mobile`
    padding-top: 10px;
  `}

  ${media.tablet`
    padding-top: 10px;
  `}

  ${media.desktop`
    padding-top: 30px;
  `}
`;

export const SocialMedias = styled.div`
  align-items: center;

  ${media.mobile`
    display: none;
  `}

  ${media.tablet`
    display: none;
  `}

  ${media.desktop`
    display: flex;
  `}
`;

export const Item = styled.a`
  background-color: ${(props) => props.theme.palette.white};
  border: 3px solid ${(props) => props.theme.palette.black};
  display: block;
  height: 35px;
  margin-right: 10px;
  transition: all 0.2s linear;
  width: 35px;

  > svg {
    display: block;
    fill: ${(props) => props.theme.palette.black};
    height: 30px;
    margin: 0 auto;
    transition: fill 0.2s linear;
    width: 20px;
  }

  &.-youtube > svg {
    height: 30px;
    width: 25px;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.black};

    > svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }
`;

export const Logo = styled(AniLink)`
  display: block;

  ${media.mobile`
    margin-left: 10px;
    width: 120px;
  `}

  ${media.tablet`
    margin-left: 10px;
     width: 220px;
  `}

  ${media.desktop`
    margin-left: -140px;
    width: 362px;
  `}
`;

export const Search = styled(AniLink)`
  align-items: center;
  background-color: ${(props) => props.theme.palette.white};
  display: flex;
  height: 35px;
  margin-right: 10px;
  outline: none;
  transition: all 0.2s linear;
  width: 35px;

  > svg {
    display: block;
    fill: ${(props) => props.theme.palette.black};
    height: 20px;
    margin: -2px auto 0;
    transition: fill 0.2s linear;
    width: 20px;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.black};
    cursor: pointer;

    > svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }

  ${media.mobile`
    border: 1px solid ${(props) => props.theme.palette.black};
  `}

  ${media.tablet`
    border: 2px solid ${(props) => props.theme.palette.black};
  `}

  ${media.desktop`
    border: 3px solid ${(props) => props.theme.palette.black};
  `}
`;
