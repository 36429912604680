import React from 'react';
import PropTypes from 'prop-types';

export default function Icon({ name }) {
  return (
    <svg>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};
