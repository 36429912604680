import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import Icon from '../icon';

export default function Pagination({ currentPage, slug, totalPages }) {
  const previousPage = currentPage === 2 ? `/categorias/${slug}` : `/categorias/${slug}/pagina/${currentPage - 1}`;
  const nextPage = `/categorias/${slug}/pagina/${currentPage + 1}`;

  return (
    <S.Pagination>
      <S.PaginationWrap>
        <S.ActionLinks
          cover
          direction="right"
          bg="#ffffff"
          duration={0.6}
          to={currentPage <= 1 ? null : previousPage}
          className={`previous ${currentPage <= 1 ? 'disabled' : ''}`}>
          <Icon name="arrow" />
        </S.ActionLinks>
        <S.TextPages>
          Página {currentPage} de {totalPages}
        </S.TextPages>
        <S.ActionLinks
          cover
          direction="left"
          bg="#ffffff"
          duration={0.6}
          to={currentPage >= totalPages ? null : nextPage}
          className={`next ${currentPage >= totalPages ? 'disabled' : ''}`}>
          <Icon name="arrow" />
        </S.ActionLinks>
      </S.PaginationWrap>
    </S.Pagination>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
};
