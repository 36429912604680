import styled from 'styled-components';
import { media } from '../../styles/media-queries';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export const WrapPreviousNext = styled.section`
  border-bottom: 1px solid ${(props) => props.theme.palette.black};
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;

  &.-singleNext {
    justify-content: flex-start;
  }

  &.-singlePrevious {
    justify-content: flex-end;
  }
`;

export const LinksNavigation = styled(AniLink)`
  align-items: center;
  display: inline-flex;
  width: 50%;
  text-decoration: none;

  section.-singlePrevious &,
  section.-singleNext & {
    ${media.mobile`
      width: 100%;
    `}
  }

  ${media.tablet`
    padding: 20px 0;
  `}

  ${media.desktop`
    padding: 20px;
  `}

  > svg {
    ${media.tablet`
      height: 24px;
      min-height: 20px;
      min-width: 20px;
      width: 24px;
    `}

    ${media.desktop`
      height: 42px;
      width: 42px;
    `}
  }

  &.-previous {
    border-right: 1px solid ${(props) => props.theme.palette.black};

    ${media.tablet`
      padding-right: 10px;
    `}

    ${media.desktop`
      padding-left: 20px;
      transition: padding-left 0.3s;
    `}

    section.-singlePrevious &,
    section.-singleNext & {
      ${media.tablet`
        border-right: 0;
      `}
    }

    > svg {
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }

  &.-next {
    justify-content: flex-end;
    text-align: right;

    ${media.tablet`
      padding-left: 10px;
    `}

    ${media.desktop`
      padding-right: 20px;
      transition: padding-right 0.3s;
    `}

    section.-singlePrevious & {
      ${media.mobile`
        border-left: 1px solid ${(props) => props.theme.palette.black};
      `}
    }

    > svg {
      margin-left: 10px;
    }
  }

  &:hover {
    &.-previous {
      padding-left: 0;
      transition: padding-left 0.3s;
    }

    &.-next {
      padding-right: 0;
      transition: padding-right 0.3s;
    }

    strong {
      text-decoration: underline;
    }
  }
`;

export const LinkInfos = styled.span``;

export const LinksLabel = styled.span`
  color: ${(props) => props.theme.palette.black};
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
`;

export const LinksTitle = styled.strong`
  color: ${(props) => props.theme.palette.black};
  display: block;
  max-width: 450px;

  ${media.tablet`
    font-size: 1.25rem;
    line-height: 1;
  `}

  ${media.desktop`
    font-size: 1.5rem;
    line-height: 1.1;
  `}
`;
