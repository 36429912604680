export const theme = {
  grid: {
    container: '1160px',
  },
  palette: {
    black: '#000000',
    white: '#ffffff',
    primary: '#ddd',
    secondary: 'gray',
    textColor: '#333',
  },
};
