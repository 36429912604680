import styled from 'styled-components';
import { media } from '../../styles/media-queries';

export const Content = styled.main`
  margin: 60px auto;
  max-width: ${(props) => props.theme.grid.container};
  text-align: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 2em;
  margin: 0 0 30px;

  ${media.mobile`
    padding: 0 15px;
  `}
`;

export const Description = styled.p`
  margin-bottom: 30px;

  ${media.mobile`
    padding: 0 15px;
  `}
`;
