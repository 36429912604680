import React from 'react';

import * as S from './styles';

export default function ErrorContent() {
  return (
    <S.Content>
      <S.Title>404 - Conteúdo Não Encontrado</S.Title>
      <S.Description>Ops! Parece que não encontramos o conteúdo que você está procurando.</S.Description>
    </S.Content>
  );
}
