import styled from 'styled-components';
import { media } from '../../styles/media-queries';

export const Wrapper = styled.div`
  padding: 60px 0;

  ${media.mobile`
    padding: 60px 15px;
  `}

  ${media.tablet`
    padding: 60px 15px;
  `}
`;

export const TitleComments = styled.h2`
  margin-bottom: 30px;
`;
