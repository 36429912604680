import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { media } from '../../styles/media-queries';

export const Menu = styled.menu`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: ${(props) => props.theme.grid.container};
  width: 100%;

  ${media.mobile`
    background-color: #eeeeee;
    margin: 10px auto 0;
    overflow-x: scroll;
    padding: 10px 0 10px 0;
  `}

  ${media.tablet`
    margin: 0 auto;
    overflow-x: scroll;
    padding-top: 30px;
  `}

  ${media.desktop`
    margin: 0 auto;
    padding-top: 30px;
  `}
`;

export const List = styled.ul`
  align-items: center;
  display: flex;
  justify-items: center;
  list-style: none;
  margin: 0 auto;
  padding: 0;
`;

export const Item = styled.li`
  border: 2px solid transparent;
  margin: 0 15px;
  transition: all 0.3s linear;

  &:hover {
    border-color: ${(props) => props.theme.palette.black};
  }

  &.-active {
    background-color: ${(props) => props.theme.palette.black};

    > a {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

export const Link = styled(AniLink)`
  color: ${(props) => props.theme.palette.black};
  display: block;
  font-size: 18px;
  line-height: 19px;
  padding: 2px 10px 5px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s linear;
`;
