import React from 'react';
import PropTypes from 'prop-types';

import Post from '../post';

import * as S from './styles';

export default function BlogPosts({ posts }) {
  return (
    <S.BlogPosts>
      {posts.map((post, index) => {
        return <Post post={post.node} key={index} />;
      })}
    </S.BlogPosts>
  );
}

BlogPosts.propTypes = {
  posts: PropTypes.array,
};
